import React, { useContext, useEffect } from "react";
import PageLayout from '../../components/PageLayout';
import GridLayout from '../../components/GridLayout';
import { GlobalDispatchContext } from "./../../context/GlobalContextProvider"
import { ARTIST_LAUYOUT } from "./../../context/consts";
import GridHoverableImageElement from "../../components/GridHoverableImageElement";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/Seo";


const ConceptArtPage = () => {

    const dispatch = useContext(GlobalDispatchContext);
    useEffect(() => dispatch({ type: "TOGGLE_THEME", theme: ARTIST_LAUYOUT }), [dispatch])

    const imageComponents = [
        <GridHoverableImageElement key="0" year="2021" titleTop="Thevoyage" titleBottom="continues">
            <StaticImage src="./../../images/artist/conceptart/01_2021_ConceptArt_Voyage_Continues.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="1" year="2020" titleTop="Thevoyage" titleBottom="oftheacolyte">
            <StaticImage src="./../../images/artist/conceptart/02_2020_ConceptArt_VoyageOftheAcolyte.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="2" year="2019" titleTop="reverse" titleBottom="flumen">
            <StaticImage src="./../../images/artist/conceptart/03_2019_ConceptArt_ReverseFlumen.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="3" year="2017" titleTop="dead" titleBottom="space">
            <StaticImage src="./../../images/artist/conceptart/04_2017_ConceptArt_DeadSpace.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="4" year="2017" titleTop="gabrielle" titleBottom="necromancer">
            <StaticImage src="./../../images/artist/conceptart/05_2017_ConceptArt_Necromancer_Gabrielle.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="5" year="2017" titleTop="floki">
            <StaticImage src="./../../images/artist/conceptart/06_2017_ConceptArt_Floki.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="6" year="2017" titleTop="living" titleBottom="theedge">
            <StaticImage src="./../../images/artist/conceptart/07_2017_ConceptArt_LivingOnTheEdge.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="7" year="2017" titleTop="monkey" titleBottom="temple">
            <StaticImage src="./../../images/artist/conceptart/08_2017_ConceptArt_Monkey_Temple.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>

    ];

    return (
        <PageLayout secondHead="Conceptart" showBack={true} linkTo="/artist">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="concept art" />
        </PageLayout >
    )
}

export default ConceptArtPage